import React from 'react';
import { css, keyframes, cx } from 'emotion';

const anim1 = keyframes`
  0% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0;
  }
  75% {
    width: 48px;
    height: 112px;
    margin-top: 0;
    margin-left: 0;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
`;

const anim2 = keyframes`
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
  25% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
  50% {
    width: 112px;
    height: 48px;
    margin-top: 0;
    margin-left: 0;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 64px;
  }
  100% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 64px;
  }
`;

const anim3 = keyframes`
  0% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 64px;
  }
  12.5% {
    width: 48px;
    height: 48px;
    margin-top: 0;
    margin-left: 64px;
  }
  25% {
    width: 48px;
    height: 112px;
    margin-top: 0;
    margin-left: 64px;
  }
  37.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  50% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  62.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  75% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  87.5% {
    width: 48px;
    height: 48px;
    margin-top: 64px;
    margin-left: 64px;
  }
  100% {
    width: 112px;
    height: 48px;
    margin-top: 64px;
    margin-left: 0;
  }
`;

const containerStyle = css`
  width: 112px;
  height: 112px;
`;

const boxStyle = css`
  border: 0;
  background-color: var(--primaryBrand);
  box-sizing: border-box;
  position: absolute;
  display: block;
`;

const box1Style = css`
  width: 112px;
  height: 48px;
  margin-top: 64px;
  margin-left: 0;
  animation: ${anim1} 4s 0s forwards ease-in-out infinite;
`;

const box2Style = css`
  width: 48px;
  height: 48px;
  margin-top: 0;
  margin-left: 0;
  animation: ${anim2} 4s 0s forwards ease-in-out infinite;
`;

const box3Style = css`
  width: 48px;
  height: 48px;
  margin-top: 0;
  margin-left: 64px;
  animation: ${anim3} 4s 0s forwards ease-in-out infinite;
`;

const centerContainer = css`
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const messageStyle = css`
  margin: 2rem 0;
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5em;
  color: var(--primaryBrand);
`;

export const Loader = props => {
  const { message } = props;
  return (
    <div className={centerContainer}>
      <div className={containerStyle}>
        <div className={cx(boxStyle, box1Style)} />
        <div className={cx(boxStyle, box2Style)} />
        <div className={cx(boxStyle, box3Style)} />
      </div>
      <p className={messageStyle}>{message}</p>
    </div>
  );
};
